import { useState } from 'react'
import Button from 'react-bootstrap/Button';
import {notification } from 'antd'

import PngIcons from './icons/Pngincons';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Slider from './Slider';
import NavbarComp from './Navbar';
import FooterComp from './Footer';
import TextArea from 'antd/es/input/TextArea';


const Contact = () => {
    const [phone, setPhone] = useState("");
    const [fullName, setFullName] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [, setConsent] = useState(false);



    const handleSubmit = async () => {
        // if (!fullName || !businessName || !phone || !email || !message || !consent) {
        //     alert('Please fill in all fields and consent to receive messages.');
        //     return;
        // }

        const formData = {
            fullName,
            businessName,
            phone,
            email,
            message,
        };

        console.log('formData',formData);
        
        try {
            const response = await fetch('https://smishtrap-app-staging-3f9153d2c851.herokuapp.com/api/message/sendEmail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            console.log('response',response);
            

            if (response.ok) {
               
                notification.success({ message: 'Email Sent Successfully!', duration: 10 });
                setFullName('')
                setPhone('')
                setBusinessName('')
                setEmail('')
                setMessage('')
                setConsent(false)
            } else {
                
                notification.error({ message: 'Failed to Send Email.', duration: 10 });
                setFullName('')
                setPhone('')
                setBusinessName('')
                setEmail('')
                setMessage('')
                setConsent(false)
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error sending message');
        }
    };



    return (
        <div className='w-100' id='contactcontainer' >
        <NavbarComp/>

            <div className='contact-firstbox'>

                <p className='contact-firstabout'>
                    Contact Us
                </p>

                <h1 className='contact-firsthead'>
                    Get in Touch with SmishTrap
                    <p className='contact-firstpara'>
                        Reach out to SmishTrap for reliable solutions to safeguard against smishing attacks.
                    </p>
                </h1>

            </div>

            <div className='secondbox'>
                <div className='secondmain'>
                    <div className='contactdiv'>
                        <h3>
                            Rewards and more
                        </h3>
                        <h1>
                            Contact Us
                        </h1>

                        <p>
                            Red Team Analysts are asked to create an impossible list of attacks.  We were asked to solve this problem and felt like sharing it.  We tried to create a self service consumable product requiring no human intervention.  Our mission is to educate employees on identifying and avoiding phishing scams, understanding cyber risks, and implementing best practices.
                        </p>

                        <p style={{ marginTop: '20px' }}>Try us today and let's make the world safer together.</p>

                        <h2 className='follow'>
                            Follow us
                        </h2>
                        <img alt='' className='contactsocial' src={PngIcons.contactsocial}></img>
                    </div>

                    <div className='sendmessage'>
                        <div className='nametext'>
                            Full Name
                        </div>
                        <input type='text' placeholder='Enter your full name....' className='nameinput'
                        
                        value={fullName} 
                        onChange={(e) => setFullName(e.target.value)}
                        ></input>
                        <div className='nametext'>
                            Business Name
                        </div>
                        <input type="text" placeholder='Enter your business name....' className='nameinput'
                          value={businessName} 
                          onChange={(e) => setBusinessName(e.target.value)}
                        ></input>
                        <div className='contactdisplay'>

                            <div style={{ width: '50%' }}>
                                <p className='nametext'>
                                    Phone Number
                                </p>

                                {/* <input type='phone' placeholder='Enter your Phone Number....' className='nameinput'></input> */}
                                <PhoneInput
                                 country={'us'} // default country code
                                    value={phone}
                                    onChange={(phone) => setPhone(phone)}

                                    inputStyle={{ padding: '12px 50px', height: '30%' }} // makes the input take full width

                                    inputClass='nameinput'
                                    placeholder="Enter your phone number..."
                                />
                            </div>
                            <div style={{ width: '50%' }}>
                                <p className='nametext'>
                                    Email Address
                                </p>
                                <input type='email' placeholder='Enter your email address...' className='nameinput'
                                  value={email} 
                                  onChange={(e) => setEmail(e.target.value)}
                                ></input>

                            </div>
                      </div>
                        <div className='nametext'>
                            How can we help?
                        </div>
                        <TextArea style={{ height: '109px' }} placeholder='Enter your message here...' className='nameinput'
                          value={message} 
                          onChange={(e) => setMessage(e.target.value)}
                        />
                        <Button onClick={handleSubmit} className='sendmsgbtn'>
                            Send Message
                        </Button>
                    </div>

                </div>
            </div>

            <div className='review-sixbox'>
                <div >
                    <h2 className='review-sixboxhead'>
                        Why people like us?
                    </h2>
                    <p className='review-sixboxpara'>
                        Let’s see what our customers are saying
                    </p>
                </div>
                <div className='carousel'>
                   <Slider/>
                </div>
            </div>
            <FooterComp />
        </div>
    )
}


export default Contact;